import { useEffect, useRef, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';

const Loading = ({ text = "" }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    let isMounted = true

    import('lottie-web').then((Lottie) => {
      if(isMounted) {
        setLottie(Lottie.default)
      }
    });

    return () => { isMounted = false }
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/loader.json',
      })

      animation.setSpeed(0.5)

      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <div className="h-32 invert dark:invert-0" ref={ref} />
      <h2 className="text-black dark:text-white">{text || "Loading data..."}</h2>
    </div>
  );
}

export default Loading
