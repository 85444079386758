import dynamic from 'next/dynamic'
import { NextSeo } from 'next-seo'
import { constConfig } from '../config/const.config'
import { useSession } from "next-auth/react"
import Loading from "../components/loading"

/**
 * Dynamic components
 */
const AccessDenied = dynamic(() => import("../components/auth/access-denied"))
const Layout = dynamic(() => import("../components/layout"))
const ExpiringDomainsWidget = dynamic(() => import("../modules/domain/widget/expiring"), {
  loading: () => <Loading/>
})

const HomePage = () => {
  const { data: session } = useSession()

  if (!session) {
    return <AccessDenied isPermission={false}/>
  }

  return (
    <>
      {/* @ts-ignore */}
      <NextSeo
        title={`Dashboard | ${constConfig.APP_NAME}`}
      />

      <Layout session={session}>

        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none w-1/2 border-r border-gray-200 dark:border-gray-700 overflow-y-auto py-6 px-4 sm:px-6 lg:px-8">
          {/* Start main area*/}

          <ExpiringDomainsWidget session={session}/>

          {/* End main area */}
        </main>
        <aside className="hidden relative xl:flex xl:flex-col flex-shrink-0 w-1/2">
          {/* Start secondary column (hidden on smaller screens) */}
          <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
            <div className="h-full border-2 border-gray-200 dark:border-gray-700 border-dashed rounded-lg" />
          </div>
          {/* End secondary column */}
        </aside>

      </Layout>

    </>
  )
}

export default HomePage
